import api from './api'

export default {
  getdata(data){
    if(window.localStorage.getItem("user") == 'csp'){
      return api.execute(`post`,`/csp/dashboard`, data)
    }
    else{return api.execute(`post`,`/dashboard`, data)}
  },
  fiterdata(data){
    if(window.localStorage.getItem("user") == 'csp'){
      return api.execute(`post`,`/csp/dashboard`, data)
    }
    else{return api.execute(`post`,`/dashboard`, data)}
  },
  dash(data){
    if(window.localStorage.getItem("user") == 'csp'){
      return api.execute(`post`,`/csp/dashheader`, data)
    }
    else{return api.execute(`post`,`/dashheader`, data)}
  },
  exportReport(data){
    return api.execute(`post`,`/export`, data)
  },
  camplist(data){
    if(window.localStorage.getItem("user") == 'csp'){
      return api.execute(`post`,`/csp/camplist`, data)
    }
    else{return api.execute(`post`,`/camplist`, data)}
  },
  managerdash(data){
    return api.execute(`post`,`/managerdash`, data)
  },
  getplandetails(){
    return api.execute(`get`,`/plancals`)
  },
  getdim(data){
    if(window.localStorage.getItem("user") == 'csp'){
      return api.execute(`post`,`/csp/getdim`, data)
    }
    else{return api.execute(`post`,`/getdim`,data)}
  },
  fraudcount(data){
    if(window.localStorage.getItem("user") == 'csp'){
      return api.execute(`post`,`/csp/fraudcount`, data)
    }else{return api.execute(`post`,`/fraudcount`,data)}
  },
  fraudgr(data){
    if(window.localStorage.getItem("user") == 'csp'){
      return api.execute(`post`,`/csp/fraudgr`, data)
    }else{return api.execute(`post`,`/fraudgr`,data)}
  },
  getplanlist(){
    return api.execute(`get`,`/planlist`)
  },
  checkOut(data){
    return api.execute(`post`,`/checkout`,data)
  },
  cancelPlan(data){
    return api.execute(`get`,`/cancelsub`,data)
  },
  hasSubscribed(){
    return api.execute(`get`,`/subscription`)
  },
  getDeviceandLanguageData(data){
    return api.execute(`post`,`/dimgrp`,data)
  },
  getTopServices(data){
    return api.execute(`post`,`/get/topservice`,data)
  },
  getclickslines(data){
    return api.execute(`post`,`/get/clickslines`,data)
  },
  visitedUser(){
    return api.execute(`get`,`/visit/pricepage`)
  },

}
